import companyService from '../../services/company';
import authService from '@/services/auth';

export default {
  name: 'selectCompany',
  data() {
    return {
      fetchingCompanyList: true,
      companyList: [],

      // Create Company Popup
      showNewCompanyPopup: false,
      errorMsg: '',
      newCompanyForm: {
        name: ''
      },
      validationRules: {
        name: [
          {
            required: true,
            message: 'Organization name is required.',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 50,
            message: 'Organization name length should be 3 to 50 characters.',
            trigger: 'blur'
          }
        ]
      },
      creatingCompany: false
    };
  },

  methods: {
    getRandomColor() {
      var letters = 'BCEDF'.split('');
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * letters.length)];
      }
      return color;
    },

    async onSelectCompany(company) {
      try {
        if (company.is_suspended) {
          this.$router.push({ name: 'suspended', query: this.$route.query });
          return;
        }

        let result = await companyService.createSessionForThatCompany(company.id);
        this.handleNewSession(result.data, true);
      } catch (error) {
        this.reportError(error);
        this.errorToast(`Something's wrong. Please contact support team.`);
      }
    },

    async fetchCompanyList() {
      try {
        let result = await companyService.fetchCompaniesOfUser();

        this.companyList = result.data;
        for (var i = 0; i < this.companyList.length; i++) {
          this.companyList[i].color = this.getRandomColor();
        }
        this.fetchingCompanyList = false;
      } catch (error) {
        this.reportError(error);
        this.errorToast(`Something's wrong. Please contact support team.`);
      }
    },

    //#region ------------ Create & Manage Company + Users ------------------

    async validateForm() {
      return new Promise((resolve) => {
        this.$refs.newCompanyForm.validate((valid) => {
          let isInvalid = valid === false;
          resolve(!isInvalid);
        });
      });
    },

    async createCompany() {
      // Validate Form
      let isValidData = await this.validateForm();
      if (!isValidData) return;

      try {
        let params = {
          name: this.newCompanyForm.name
        };
        this.creatingCompany = true;
        await companyService.createCompany(params);

        this.fetchCompanyList();
        this.clearCompanyForm();
        this.successToast('Organization Created.');
      } catch (error) {
        this.reportError(error);
        this.errorToast('Something is wrong, please contact support.');
        this.creatingCompany = false;
      }
    },

    clearCompanyForm() {
      this.showNewCompanyPopup = false;
      this.newCompanyForm.name = '';
    },

    onLogout() {
      authService.logout();
      this.$router.replace('/login');
    }

    //#endregion ------------ Create & Manage Company + Users ------------------
  },

  created() {
    this.$store.dispatch('setHeaderVisiblity', false);
  },

  mounted() {
    this.fetchCompanyList();
    document.getElementById('sideMenu').style.display = 'none';
  },

  beforeDestroy() {
    document.getElementById('sideMenu').style.display = 'block';
  }
};
