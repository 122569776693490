<template>
  <div id="selectCompanyPage" class="container">
    <div v-if="fetchingCompanyList" v-loading="true" style="background: transparent; margin-top: 30vh"></div>
    <!-- Select Company Card -->
    <div v-if="!fetchingCompanyList">
      <!-- Company List -->
      <template v-if="companyList.length > 0">
        <div class="w-full text-center mb-4">
          <span>Select Organization</span>
        </div>

        <!-- Show Grid View if more than 10 Companies -->
        <el-row v-if="companyList.length > 9">
          <el-col :span="8" v-for="(company, index) in companyList" :company="company" :key="index" :value="company.id">
            <div class="companyListContainer">
              <div class="companyItem" @click="onSelectCompany(company)">
                <div class="companyNameIcon" v-bind:style="{ background: company.color }">
                  {{ company.name.charAt(0) }}
                </div>
                <div class="companyNameIcon companyName">
                  {{ company.name }}
                </div>

                <!-- Right Arrow -->
                <div class="rightArrow" v-bind:style="{ color: company.color }">
                  <svg viewBox="0 0 10 9" width="100%" style="margin-top: 5px; height: 15px">
                    <g stroke="currentColor" stroke-width="1.5" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M6.108 8.25l3.16-3.457-3.16-3.391M8.87 4.826H4.124"></path>
                      <path d="M6.108 8.25l3.16-3.457-3.16-3.391M1.151 4.826h-.4M8.87 4.826H4.124"></path>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>

        <!-- Show single company per row view if single company -->
        <div v-else style="width: 600px; margin: auto">
          <div class="companyListContainer" v-for="(company, index) in companyList" :company="company" :key="index" :value="company.id">
            <div class="companyItem" @click="onSelectCompany(company)" style="max-width: 600px; margin-left: auto; margin-right: auto">
              <div class="companyNameIcon" v-bind:style="{ background: company.color }">
                {{ company.name.charAt(0) }}
              </div>
              <div class="companyNameIcon companyName">
                {{ company.name }}
              </div>

              <!-- Right Arrow -->
              <div class="rightArrow" v-bind:style="{ color: company.color }">
                <svg viewBox="0 0 10 9" width="100%" style="margin-top: 5px; height: 15px">
                  <g stroke="currentColor" stroke-width="1.5" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M6.108 8.25l3.16-3.457-3.16-3.391M8.87 4.826H4.124"></path>
                    <path d="M6.108 8.25l3.16-3.457-3.16-3.391M1.151 4.826h-.4M8.87 4.826H4.124"></path>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- No Company Message -->
      <div v-else>
        <div style="margin-top: 20vh; text-align: center; color: #777">No organization allocated to you yet.</div>
        <div style="margin: 0px; text-align: center; color: #777">
          In case you need any help, you can reachout to us at connect@growlytics.in.
          <br /><br />
        </div>
      </div>

      <!-- Create Organization Button -->
      <el-button class="createOrganizationtBtn" type="primary" @click="showNewCompanyPopup = true" v-if="$store.state.token.email == 'ravi@growlytics.in'"> Create New Organization </el-button>
      <!-- <el-button class="createOrganizationtBtn" type="primary" @click="showNewCompanyPopup = true"> Create New Organization </el-button> -->
    </div>

    <!-- company creation pop up -->
    <el-dialog id="companyForm" title="Create Organization" :visible.sync="showNewCompanyPopup" width="500px" center :before-close="clearCompanyForm">
      <div class="errorMsg" v-if="errorMsg">{{ errorMsg }}</div>

      <el-form ref="newCompanyForm" :model="newCompanyForm" :rules="validationRules">
        <el-form-item label="Organization Name" prop="name">
          <el-input v-model="newCompanyForm.name" placeholder="e.g. My Organization"> </el-input>
        </el-form-item>

        <div style="text-align: center; margin-top: 40px">
          <el-button type="success" @click="createCompany" :loading="creatingCompany">Save </el-button>
          <el-button type="danger" @click="clearCompanyForm">Cancel</el-button>
        </div>
      </el-form>
    </el-dialog>

    <!-- // logout button -->
    <el-button slot="reference" type="danger" size="small" @click="onLogout()" style="position: fixed; top: 25px; right: 40px">Logout</el-button>
  </div>
</template>

<script>
import SelectCompanyComponent from './selectCompanyComponent';
export default SelectCompanyComponent;
</script>

<style lang="scss">
#selectCompanyPage {
  margin-top: 50px;
  width: 1200px;
  min-height: 100vh;

  .el-card__header {
    background: #f7f8f9;
    font-weight: bold;
  }
  .header {
    margin-bottom: 30px;
  }

  .el-card__body {
    padding-bottom: 0px;
  }

  .companyListContainer {
    display: inline;

    .companyItem {
      display: inline-block;
      width: 94%;
      margin-right: 7px;
      background: #fff;
      color: #51667c;
      box-shadow: 0 0 1px rgba(67, 90, 111, 0.415), 0 2px 4px -2px rgba(67, 90, 111, 0.301);
      border-radius: 4px;
      padding: 12px;
      margin-bottom: 15px;
      position: relative;
      cursor: pointer;
      text-decoration: none;
      transition-property: color, border;
      transition-duration: 225ms;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      font-size: 14px;

      // margin-top: 20px;
      .companyNameIcon {
        // border: 1px solid red;
        padding: 5px 10px 5px 10px;
        background: #ddd;
        border-radius: 3px;
        display: inline-block;
        width: 30px;
        text-align: center;
      }

      .companyName {
        background: none;
        width: auto;
      }

      .rightArrow {
        width: 30px;
        text-align: right;
        float: right;
      }

      &:hover {
        background: #f7f8fa;
      }
    }
  }

  .createOrganizationtBtn {
    margin-top: 10px;
    margin-bottom: 30px;
    width: 94%;
  }
}
</style>
